(function ($) {

    $(document).ready(function () {
        checkDevice();

        // check if form is submitted
        thankyoupage();

        if (readCookie('usico') == 'topbanner') {
            $('.top-banner').hide();
        }

        $('#close__top-banner').on('click', function () {
            $('.top-banner').hide(100);
            createCookie();
        })

        if ($('body').hasClass('is-desktop')) {
            $(".arrow").click(function () {
                var box = $(".gamme__wrapper"),
                    x;
                if ($(this).hasClass("arrow-right")) {
                    x = ((box.width() / 2)) + box.scrollLeft();
                    box.animate({
                        scrollLeft: x,
                    })
                } else {
                    x = ((box.width() / 2)) - box.scrollLeft();
                    box.animate({
                        scrollLeft: -x,
                    })
                }
                if (x > 0) {
                    $('.arrow-left').css({'display': 'flex'});
                }
                if (x < 700 && x >= 0) {
                    $('.arrow-left').hide();
                }
                if (x > 1400) {
                    $('.arrow-right').hide();
                } else {
                    $('.arrow-right').show(100);
                }
            })
        }

        if ($('section').hasClass('taxonomy-block')) {

            // more-options listing products
            var moreOptions = document.getElementById('more-options');
            var filterWrapper = document.getElementById('filter-wrapper');

            moreOptions.addEventListener('click', function () {
                if (filterWrapper.classList.contains('filter-wrapper--open')) {
                    filterWrapper.classList.remove('filter-wrapper--open');
                } else {
                    filterWrapper.classList.add('filter-wrapper--open');
                }
            });
        }
    });

})(jQuery);

var checkDevice = function () {
    /* Check is mobile */
    var bMobile =   // will be true if running on a mobile device
        navigator.userAgent.indexOf("Mobile") !== -1 ||
        navigator.userAgent.indexOf("iPhone") !== -1 ||
        navigator.userAgent.indexOf("Android") !== -1 ||
        navigator.userAgent.indexOf("Windows Phone") !== -1;
    if (bMobile) {
        $('body').addClass('is-mobile');
    } else {
        $('body').addClass('is-desktop');
    }
    return bMobile;
    /* END - Check is mobile */
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function createCookie() {
    var expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    document.cookie = 'usico=topbanner; expires=' + expiryDate.toGMTString();
}

function thankyoupage() {
    // Redirect after form contact submission
    document.addEventListener('wpcf7mailsent', function (event) {

        if (
            event.detail.contactFormId == '996' || // decorex
            event.detail.contactFormId == '6' // usico
        ) {
            location = "/merci";
        }
    }, false);
}
